<template>
	<div class="modal_component modal_error">
	
		<div class="modal_component_header modal_header_error">
			{{ modalData.title }}
		</div>
		
		
		<div class="modal_component_content modal_content_error" v-html="modalData.description"></div>
		
		
		<div class="modal_component_footer modal_footer_error">
			<button class="button" @click="close()">Ok</button>
		</div>
		
	</div>
</template>


<script>
	import { mapActions } from 'vuex';

	export default {
		name: 'ModalWarning',
		data: function() {
			return {
			}
		},
		computed: {
			modalData(){
				return this.$store.getters.getModalData;
			}
		},
		methods: { 		
			...mapActions([
				'hideModal'
			]),
			close(){
				this.hideModal();
			}
		}
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
