<template>
	<div class="modal_component modal_image">
	
		<div class="modal_component_header modal_header_image">
			<div class="modal_header_title">
				{{ modalData.title }}
			</div>

			<div class="modal_header_exit">
				<font-awesome-icon @click="close()" :icon="['fa', 'times']" />
			</div>
		</div>
		
		<div class="modal_component_content modal_content_image">
			<img :src="getImgUrl(modalData.image)">
		</div>
		
	</div>
</template>


<script>
	import { mapActions } from 'vuex';

	export default {
		name: 'ModalImage',
		data: function() {
			return {
			}
		},
		computed: {
			modalData(){
				return this.$store.getters.getModalData;
			},
		},
		methods: { 		
			...mapActions([
				'hideModal'
			]),

			getImgUrl(image) {
				return require('@/assets/img/'+image);
			},

			close(){
				this.hideModal();
			}
		}
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
