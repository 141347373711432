import axios from 'axios'
import Vue from 'vue';
import * as general_mutations from '../mutations';
import * as general_state from '../store';
import router from '../../router/router'
import * as evalvation_questionnaire from './evalvation_questionnaire';

const state = {
    // All programs in year
	evalvation_programs_all: {},

	// All programs in year for this user
	evalvation_programs_user: {},

	// Programs that user choosed to fill out at once
	evalvation_programs_multiple_fill: [],

	// List of available departments
	evalvation_departments: {},

	// Data used in setting user accesses (need to be separated from general lists because of year changing)
	user_access_lists: {
		year: '',
		programs_list: {},
		departments_list: {}
	}
};


const getters = {
	// Return all programs
	evalvation_programs_all: store => {
		return state.evalvation_programs_all;
	},

	// Return all programs for this user
	evalvation_programs_user: store => {
		return state.evalvation_programs_user;
	},

	// Return all available departments
	evalvation_departments: store => {
		return state.evalvation_departments;
	},

	// Return programs that user choosed to fill out at once
	evalvation_programs_multiple_fill: store => {
		return state.evalvation_programs_multiple_fill;
	},

	// Return lists and data for user access
	user_access_lists: store => {
		return state.user_access_lists;
	},
};


const mutations = {
	// Prepare list of all programs in year
    getProgramsListAll: (state, payload) => {
		state.evalvation_programs_all = payload;
	},

	// Prepare list of all programs for this user in year
	getProgramsListForUser: (state, payload) => {
		state.evalvation_programs_user = payload;
	},

	// Prepare list of departments
	getDepartmentsList: (state, payload) => {
		state.evalvation_departments = payload;
	},

	// Prepare list of all programs in in user access lists
	getProgramsListUserAccess: (state, payload) => {
		Vue.set(state.user_access_lists, 'programs_list', payload);
	},

	// Set year of lists in user access lists
	getYearUserAccess: (state, payload) => {
		Vue.set(state.user_access_lists, 'year', payload);
	},

	// Prepare list of departments in user access lists
	getDepartmentsListUserAccess: (state, payload) => {
		Vue.set(state.user_access_lists, 'departments_list', payload);
	},

	// Prepare list of programs that user choosed to fill out at once
	setProgramsMultipleFill: ({commit}, payload) => {
		state.evalvation_programs_multiple_fill = payload;
	},

	// Reset list of programs that user choosed to fill out at once
	resetProgramsMultipleFill: ({commit}) => {
		state.evalvation_programs_multiple_fill = [];
	},

	// Set finnished to 0 on repeal
	repealProgramFinished: (state, payload) => {
		evalvation_questionnaire.default.state.evalvation_program_detail.finished = '0';
	},

	// Set finnished to 1 of deparment
	setDepartmentFinished: (state, payload) => {
		evalvation_questionnaire.default.state.evalvation_department_detail.finished = '1';
	},

	// Set submitted = 1 on all programs in this faculty
	allProgramsSubmitted: (state) => {
		for (let program_id in state.evalvation_programs_user) {
			if(state.evalvation_programs_user[program_id].faculty_id == general_state.state.faculty.id)
				state.evalvation_programs_user[program_id].submitted = 1;
		}
	},

	// Set submitted = 0 on all programs in this faculty
	allProgramsSubmittedRepeal: (state) => {
		for (let program_id in state.evalvation_programs_user) {
			if(state.evalvation_programs_user[program_id].faculty_id == general_state.state.faculty.id)
				state.evalvation_programs_user[program_id].submitted = 0;
		}
	},
};


const actions = {
	// Prepare list of all programs
	getProgramsListAll: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		let year = (payload && payload.year) ? payload.year : general_state.state.year;
		axios.get('?module=evalvation_program&action=get_list&year='+year)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						if(payload && payload.year)
							commit('getProgramsListUserAccess', res.data);
						else
							commit('getProgramsListAll', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Prepare list of program for this user 
	getProgramsListForUser: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=evalvation_program&action=get_list_user&year='+general_state.state.year)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getProgramsListForUser', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Prepare list of program for this faculty (admin) 
	getProgramsListForFaculty: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		let year = (payload && payload.year) ? payload.year : general_state.state.year;
		axios.get('?module=evalvation_program&action=get_list_faculty&year='+year+'&faculty='+general_state.state.faculty.id)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						if(payload && payload.year)
							commit('getProgramsListUserAccess', res.data);
						else
							commit('getProgramsListForUser', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Prepare list of departments for faculty
	getDepartmentsListForFaculty: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		let year = (payload && payload.year) ? payload.year : general_state.state.year;
		axios.get('?module=evalvation_oddelek&action=get_list_faculty&year='+year+'&faculty='+general_state.state.faculty.id)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						if(payload && payload.year)
							commit('getDepartmentsListUserAccess', res.data);
						else
							commit('getDepartmentsList', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Prepare list of departments
	getDepartmentsList: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		let year = (payload && payload.year) ? payload.year : general_state.state.year;
		axios.get('?module=evalvation_oddelek&action=get_list&year='+year)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						if(payload && payload.year)
							commit('getDepartmentsListUserAccess', res.data);
						else
							commit('getDepartmentsList', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Prepare list of departments for user
	getDepartmentsListForUser: ({commit}) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=evalvation_oddelek&action=get_list_user&year='+general_state.state.year)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getDepartmentsList', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Prepare lists and data for user access
	getListsForUserAccess: ({commit, dispatch}, payload) => {
		if(general_state.state.user.type == 2){
			dispatch('getDepartmentsList', payload);
			if(payload.year == general_state.state.year && Object.keys(state.evalvation_programs_all).length > 0)
				Vue.set(state.user_access_lists, 'programs_list', state.evalvation_programs_all);
			else
				dispatch('getProgramsListAll', payload);
		}
		else if(general_state.state.user.type == 1){
			if(payload.year == general_state.state.year && Object.keys(state.evalvation_departments).length > 0)	
				Vue.set(state.user_access_lists, 'departments_list', state.evalvation_departments);
			else
				dispatch('getDepartmentsListForFaculty', payload);

			if(payload.year == general_state.state.year && Object.keys(state.evalvation_programs_user).length > 0)	
				Vue.set(state.user_access_lists, 'programs_list', state.evalvation_programs_user);
			else
				dispatch('getProgramsListForFaculty', payload);
		}
		commit('getYearUserAccess', payload.year);
	},

	// Set list of programs that user choosed to fill out at once
	setProgramsMultipleFill: ({commit}, payload) => {
		let arr = state.evalvation_programs_multiple_fill;

		if(payload.add)
			arr.push(payload.id);
		else
			arr = arr.filter(item => item !== payload.id)

		commit('setProgramsMultipleFill', arr);
	},

	// Reset list of programs that user choosed to fill out at once
	resetProgramsMultipleFill: ({commit}) => {
		commit('resetProgramsMultipleFill');
	},

	// Check if data of multiple programs is the same
	checkMultiprogramData: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_data&action=check_multiprogram_data&year='+general_state.state.year+'&faculty='+general_state.state.faculty.id, {program_ids: state.evalvation_programs_multiple_fill})
			.then(
				res => {
					if(res.data.error){		
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						//data is the same
						if(res.data.check)
							router.replace('/samoevalvacija/podrobnosti/1/'+state.evalvation_programs_multiple_fill.join(','));
						//data is different
						else
							general_mutations.showModal(general_state.state, {component: 'evalvation/MultipleFillOverrideWarning', data: {program_ids: state.evalvation_programs_multiple_fill}});

						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Copy data of multiple programs
	copyMultiprogramData: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_data&action=copy_multiprogram_data&year='+general_state.state.year+'&faculty='+general_state.state.faculty.id, {program_ids: state.evalvation_programs_multiple_fill})
			.then(
				res => {
					if(res.data.error){		
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						if(res.data.success)
							router.replace('/samoevalvacija/podrobnosti/1/'+state.evalvation_programs_multiple_fill.join(','));
						else
							general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka pri združevanju podatkov"});

						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Set program to finished
	setProgramFinished: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_program&action=set_program_finished&program_id='+payload.program_id, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						general_mutations.hideModalWorking(general_state.state);

						if(general_state.state.user.type > 1)
							dispatch('getProgramsListAll'); 
						if(general_state.state.user.type > 0)
							dispatch('getProgramsListForFaculty');
						else
							dispatch('getProgramsListForUser');

						general_mutations.showModal(general_state.state, {component: 'evalvation/FillFinished', data:{type: 1}});
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Set department to finished
	setDepartmentFinished: ({commit, dispatch}, payload) => {
		axios.get('?module=evalvation_program&action=set_department_finished&department_id='+payload.department_id)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						general_mutations.showModal(general_state.state, {component: 'evalvation/FillFinished', data:{type: 2}});
						commit('setDepartmentFinished', payload);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},

	//Repeal program to finished
	repealProgramFinished: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=evalvation_program&action=repeal_program_finished&program_id='+payload.program_id)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						general_mutations.hideModalWorking(general_state.state);

						commit('repealProgramFinished', payload);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Send a submittion of all programs of faculty
	sendProgramsSubmittion: ({commit}) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_data&action=submit_faculty_data&faculty='+general_state.state.faculty.id+'&year='+general_state.state.year)
		.then(
			res => {
				if(!res.data.success){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('allProgramsSubmitted');
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Send a repeal of submittion of all programs of faculty
	sendProgramsSubmittionRepeal: ({commit}) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_data&action=repeal_submit_faculty_data&faculty='+general_state.state.faculty.id+'&year='+general_state.state.year)
		.then(
			res => {
				if(!res.data.success){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('allProgramsSubmittedRepeal');
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	//Export package all programs data
	exportAllProgramsPackage: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		axios.get('?module=export&action=get_evalvation_export_all_programs&format='+payload.format+'&faculty='+general_state.state.faculty.id+'&year='+general_state.state.year)
			.then(
				res => {
					if(!res.data.success){		
						general_mutations.hideModalWorking(general_state.state);		
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						general_mutations.hideModalWorking(general_state.state);
						dispatch('downloadFile', {url:res.data.export_link, filename:res.data.export_filename});
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	//Set programs hidden
	toggleHidePrograms: ({commit, dispatch}, payload) => {
		let hide = (payload == 1) ? 0 : 1;
		axios.get('?module=evalvation_program&action=toggle_hide_programs&hide='+hide+'&year='+general_state.state.year)
			.then(
				res => {
					if(!res.data.success){		
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						dispatch('getProgramsListAll'); 
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			 
	},
		
};


export default{
	state,
	getters,
	actions,
	mutations	
}