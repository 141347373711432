import axios from 'axios'
import Vue from 'vue';
import * as general_mutations from '../mutations';
import * as general_getters from '../getters';
import * as general_state from '../store';

const state = {
    // Dashboard sets
	evalvation_dashboard_sets: {},

	// Dashboard components
	evalvation_dashboard_components: {},

	// Dashboard values
	evalvation_dashboard_values: {},

	// Dashboard averages
	evalvation_dashboard_averages: {},

	// Dashboard for program
	evalvation_dashboard_program: {},

};


const getters = {
	// Dashboard sets
	evalvation_dashboard_sets: store => {
		return state.evalvation_dashboard_sets;
	},

	// Dashboard components
	evalvation_dashboard_components: store => {
		return state.evalvation_dashboard_components;
	},

	// Dashboard values
	evalvation_dashboard_values: store => {
		return state.evalvation_dashboard_values;
	},

	// Dashboard averages
	evalvation_dashboard_averages: store => {
		return state.evalvation_dashboard_averages;
	},

	// Dashboard for program
	evalvation_dashboard_program: store => {
		return state.evalvation_dashboard_program;
	},
};


const mutations = {
	// Dashboard sets
    evalDashboardSets: (state, payload) => {
		state.evalvation_dashboard_sets = payload;
	},

	// Dashboard components
    evalDashboardComponents: (state, payload) => {
		state.evalvation_dashboard_components = payload;
	},

	// Dashboard values
    evalDashboardValues: (state, payload) => {
		state.evalvation_dashboard_values = payload;
	},

	// Dashboard averages
    evalDashboardAverages: (state, payload) => {
		state.evalvation_dashboard_averages = payload;
	},

	// Dashboard for program
	evalDashboardProgram: (state, payload) => {
		state.evalvation_dashboard_program = payload;
	},

};


const actions = {

	// Get evalvation dashboard
	getEvalDashboard: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let year_from = general_state.state.year.substring(general_state.state.year.lastIndexOf('/')+1)-5;
		let year_to = general_state.state.year.substring(general_state.state.year.lastIndexOf('/')+1)-1;
		
		axios.get('?module=evalvation_dashboard&action=get_dashboard&year_from='+year_from+'&year_to='+year_to
				+'&faculty='+general_state.state.faculty.id)
			.then(
				res => {
					if(res.data.error){		
						general_mutations.hideModalWorking(general_state.state);
						if(res.data.error !== 'no data')
							general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						/* switch(payload.survey){
							case 'after1':
								commit('evalSurveyAfter1', res.data);
								break;
						} */

						commit('evalDashboardSets', res.data.sets);
						commit('evalDashboardComponents', res.data.components);
						commit('evalDashboardValues', res.data.values);
						commit('evalDashboardAverages', res.data.averages);

						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Get program dashboard
	getProgramDashboard: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		axios.post('?module=evalvation_dashboard&action=get_program_dashboard', payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('evalDashboardProgram', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

};


export default{
	state,
	getters,
	actions,
	mutations	
}