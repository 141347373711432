<template>
    <div class="main_holder analytics">

        <div class="content_holder">

            <div class="content">

                <h2>Analitika</h2>

                <h3>V spodnjem grafu je prikazan delež zaključenih programov za posamezno članico</h3>

                <div class="analytics_content">
                    <div class="analytics_charts">
                        <div class="chart_canvas">
                            <span>Delež zaključenih programov - filtrirani (izključeni so programi vrste izpopolnjevanje, programi v izteku, 
                                FDV programi zaključeni v prejšnjem študijskem letu, brez vpisanih študentov v izbranem študijskem letu)</span>
                            <!-- <horizontal-bar-chart :chart-data="data_collection" :options="chart_options"></horizontal-bar-chart> -->
                            <Graph
                                v-if="fillData('filtered')"
                                :graph_data="fillData('filtered')"
                                :graph_layout="graphLayout"
                                :display_mode_bar="false">
                            </Graph>
                        </div>

                        <div class="chart_canvas">
                            <span>Delež zaključenih programov - vsi, nefiltrirani</span>
                            <!-- <horizontal-bar-chart :chart-data="data_collection" :options="chart_options"></horizontal-bar-chart> -->
                            <Graph
                                v-if="fillData('global')"
                                :graph_data="fillData('global')"
                                :graph_layout="graphLayout"
                                :display_mode_bar="false">
                            </Graph>
                        </div>

                        <!--<div class="chart_canvas">
                            <line-chart :chart-data="data_collection" :options="chart_options"></line-chart>
                        </div>-->
                    </div>

                    <div class="analytics_exports">

                        <div class="as_link icon export_item" :title="'Oddana poročila programov ' + year" @click="exportEvalvationAnalytics({export_type: 'get_zip_programs_data'})">
                            <font-awesome-icon :icon="['far', 'file-archive']" /> Oddana poročila programov {{ year }}
                        </div>

                        <!-- <div class="icon export_item" :class="{as_link: chart_image_string, grey: !chart_image_string}" 
                            :title="'Graf deleža zaključenih programov ' + year" @click="exportChart()">
                            <font-awesome-icon :icon="['far', 'file-pdf']" /> Graf deleža zaključenih programov {{ year }}
                        </div>    --> 

                        <div class="as_link icon export_item" :title="'Seznam programov ' + year" @click="exportEvalvationAnalytics({export_type: 'get_excel_programs'})">
                            <font-awesome-icon :icon="['far', 'file-excel']" /> Seznam programov {{ year }}
                        </div>

                        <div class="as_link icon export_item" :title="'Časi odgovorov ' + year" @click="exportEvalvationAnalytics({export_type: 'get_excel_timestamps'})">
                            <font-awesome-icon :icon="['far', 'file-excel']" /> Časi odgovorov {{ year }}
                        </div>    

                    </div>
                </div>
            </div>
        </div>

    </div>
  
</template>

<script>
import { mapActions } from 'vuex';
import { color_main } from '../../scss/basic/colors.scss'

import Graph from "@/components/general/Graph.vue";

export default {
    components: {
        Graph,
    },
    data() {
        return {
            graphLayout: {
                showlegend: false,
                width: 800,
                height: 800,
                title: null,
                margin: {
                    t: 30,
                    l: 50,
                    r: 50,
                },
                xaxis: {
                    range: [1, 119],
                    dtick: 20,
                },
                yaxis: {
                    type: 'category',
                    dtick: 1,
                    tickfont: {
                        size: 16, // Adjust the font size of y-axis labels
                    },
                    automargin: true, // Adjust the margin to fit the larger y-axis labels
                    ticklen: 5, // Adjust the length of ticks on the y-axis
                    tickwidth: 0, // Adjust the width of ticks on the y-axis
                    showline: false, // Remove the line between ticks on the y-axis
                }
            },
            label_finished_share: 'Delež zaključenih programov',
            chart_image_string: ''
        };
    },
    /* watch: {
        getStatisticsByFaculty(newVal, oldVal) {
            this.fillData();
        }
    }, */
    computed: {
        year() {
            return this.$store.getters.year;
        },
        getFacultiesNames() {
            return Object.values(this.getStatisticsByFaculty).map(faculty => faculty.name);
        },
        getFacultiesAcronyms() {
            return Object.values(this.getStatisticsByFaculty).map(faculty => faculty.acronym);
        },
        getStatisticsByFaculty() {
            return this.$store.getters.evalvation_statistics_by_faculty;
        },
        sortedStatisticsByFacultyGlobal() {
            if(this.getStatisticsByFaculty.global == null) return [];

            let values = Object.values(this.getStatisticsByFaculty.global);
            return values.sort(function (a, b) {
                return a.programs_finished_percentage - b.programs_finished_percentage;
            });
        },
        sortedStatisticsByFacultyFiltered() {
            if(this.getStatisticsByFaculty.filtered == null) return [];

            let values = Object.values(this.getStatisticsByFaculty.filtered);
            return values.sort(function (a, b) {
                return a.programs_finished_percentage - b.programs_finished_percentage;
            });
        },
    },
    methods: {
        ...mapActions([
            'exportEvalvationAnalytics',
            'getEvalvationStatisticsByFaculty'
        ]),
        
        fillData(chart_data) {
            let x = [];
            let y = [];
            let labels = [];

            let CharData = (chart_data == 'global') ? this.sortedStatisticsByFacultyGlobal : this.sortedStatisticsByFacultyFiltered;

            for(let f_data of CharData){
                x.push(f_data.programs_finished_percentage);
                y.push(f_data.acronym);
                labels.push(f_data.programs_finished_percentage+"% ("+f_data.programs_finished+'/'+f_data.programs_all+')');
            }

            return [{
                x: x,
                y: y,
                text: labels,
                textposition: 'outside',
                clip: 'off', // Prevent clipping of text labels
                type: 'bar',
                orientation: 'h',
                hoverinfo: 'none', // Disable hover
                marker: {
                    color: "#E12A26",         // Set the color of the bars
                }, 
                
            }];
        },
    },
    created () {
        //this.fillData();
        this.getEvalvationStatisticsByFaculty();
    },
}
</script>


<style lang="scss" scoped>
    @import "../../scss/style.scss";
</style>
