import axios from 'axios'
import * as general_mutations from '../mutations';
import * as general_state from '../store';

const state = {

	// Statistics by faculty for evalvation
	evalvation_statistics_by_faculty: {},
    
};


const getters = {
	// Return statistics by faculty for evalvation
	evalvation_statistics_by_faculty: store => {
		return state.evalvation_statistics_by_faculty;
	},
};


const mutations = {
	// Prepare statistics by faculty
	getEvalvationStatisticsByFaculty: (state, payload) => {
		state.evalvation_statistics_by_faculty = payload;
	},
};


const actions = {
	// Evalvation exports
	exportEvalvationAnalytics: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=evalvation_analytics&action='+payload.export_type+'&year='+general_state.state.year)
			.then(
				res => {
					if(!res.data.success){				
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						general_mutations.hideModalWorking(general_state.state);
						dispatch('downloadFile', {url:res.data.export_link, filename:res.data.export_filename});
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Prepare statistics by faculty
	getEvalvationStatisticsByFaculty: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		let year = (payload && payload.year) ? payload.year : general_state.state.year;
		axios.get('?module=evalvation_analytics&action=get_chart_global&year='+year)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getEvalvationStatisticsByFaculty', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},
};


export default{
	state,
	getters,
	actions,
	mutations	
}