<template>
    <div>
        <div v-if="Object.keys(dataset_data).length == 0">
            <table class="program_dataset_table_simple">
                <tbody>
                    <tr>
                        <td class="thick_top_border">Ni podatkov za ta program</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div v-else-if="dataset_name == 'diplomanti' || dataset_name == 'prehodnost'">
            <table class="program_dataset_table_simple">
                <thead>
                    <tr>
                        <th v-for="(value, key) in dataset_data" :key="key">{{ key }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td v-for="(value, key) in dataset_data" :key="key">{{ value }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else-if="dataset_name == 'vpis' || dataset_name == 'tuji_vpisani'">
            <table class="program_dataset_table_simple">
                <thead>
                    <tr>
                        <th v-for="(value, key) in dataset_data.header" :key="key">{{ value }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="dataset_data.redni" v-for="(values, letnik, indexr) in dataset_data.redni" :key="'r'+letnik">
                        <td :class="{no_border: (indexr!=0)}">{{ indexr == 0 ? "REDNI" : ""}}</td>
                        <td>{{ letnik }}</td>
                        <td v-for="(value, key) in table_header_trimmed" :key="key">
                            {{ (Object.keys(dataset_data.redni[letnik]).includes(value) ? dataset_data.redni[letnik][value] : 0) }}
                        </td>
                    </tr>
                    <tr v-if="dataset_data.izredni" v-for="(values, letnik, indexi) in dataset_data.izredni" :key="'i'+letnik">
                        <td :class="{no_border: (indexi!=0)}">{{ indexi == 0 ? "IZREDNI" : ""}}</td>
                        <td>{{ letnik }}</td>
                        <td v-for="(value, key) in table_header_trimmed" :key="key">
                            {{ (Object.keys(dataset_data.izredni[letnik]).includes(value) ? dataset_data.izredni[letnik][value] : 0) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
    name: 'TableData',

    props: {
        dataset_name: {
            type: [String],
            required: true
        },
        program_id: {
            type: [String, Number],
            required: true
        }
    },
    data: function () {
        return {

        }
    },
    computed: {
        // Get program dashboard info
        dataset_info() {
            if(this.$store.getters.evalvation_dashboard_program 
                    && this.$store.getters.evalvation_dashboard_program[this.program_id]
                    && this.$store.getters.evalvation_dashboard_program[this.program_id][this.dataset_name]
                    && this.$store.getters.evalvation_dashboard_program[this.program_id][this.dataset_name].info) 
                return this.$store.getters.evalvation_dashboard_program[this.program_id][this.dataset_name].info;
            return {};
        },

        // Get program dashboard data
        dataset_data() {
            if(this.$store.getters.evalvation_dashboard_program 
                    && this.$store.getters.evalvation_dashboard_program[this.program_id] 
                    && this.$store.getters.evalvation_dashboard_program[this.program_id][this.dataset_name] 
                    && this.$store.getters.evalvation_dashboard_program[this.program_id][this.dataset_name].data) 
                return this.$store.getters.evalvation_dashboard_program[this.program_id][this.dataset_name].data;
            return {};
        },

        table_header_trimmed() {
            if(this.dataset_data && this.dataset_data.header) {
                return this.dataset_data.header.slice(2);
            }
            return [];
        }
        
    },

    methods: {
        ...mapActions([
        ]),

    }
}
</script>


<style lang="scss" scoped>
</style>
