import axios from 'axios'
import router from '../router/router'
import * as general_state from '../store/store';
import * as general_mutations from '../store/mutations';
import * as sections_list from '../store/modules/sections_list';
import * as evalvation from '../store/modules/evalvation';
import * as analytics from '../store/modules/analytics';
import * as evalvation_questionnaire from '../store/modules/evalvation_questionnaire';
import * as sections_detail from '../store/modules/sections_detail';
import * as sections_attachments from '../store/modules/sections_attachments';
import * as users from '../store/modules/users';


export const showModal = ({commit}, payload) => {
	commit('showModal', payload);
};

export const hideModal = ({commit}, payload) => {
	commit('hideModal', payload);
};

export const showModalWorking = ({commit}) => {
	commit('showModalWorking');
};

export const hideModalWorking = ({commit}) => {
	commit('hideModalWorking');
};


// Login user
export const loginUser = ({commit, dispatch}, payload) => {
    axios.post('?module=user&action=login_user&email='+payload.email, payload)
        .then(
            res => {
                // Login succesful
                if(res.data.success === true){

                    // Commit changes to store (user, faculty, token...)
                    dispatch('getActiveYear');
                    commit('setAuthData', res);
                    commit('setToken', res.data.token);

                    // Save token, faculty_id and user id to local storage
                    localStorage.setItem('token', res.data.token);
                    //localStorage.setItem('user_id', res.data.user.id);
                    localStorage.setItem('faculty', JSON.stringify(res.data.faculty));
                    localStorage.setItem('year', general_state.state.active_year_date.active_year);
                    let expirationDate = new Date(new Date().getTime() + general_state.state.expirationTime).getTime();
                    localStorage.setItem('expirationDate', expirationDate);

                    //Redirect based on access
                    if(general_state.state.user.access_sections == 1){
                        router.replace('/podrocja/');
                    }
                    else if(general_state.state.user.access_work_plan == 1){
                        router.replace('/program-dela/');
                    }
                    else if(general_state.state.user.access_evalvation == 1){
                        router.replace('/samoevalvacija/programi/');
                    }
                }
                // Login failed
                else{
                    alert(res.data.error);
                }
            }
        )
        .catch(error => console.log(error))		
};

// Logout user
export const logoutUser = ({commit}) => {

    // Commit changes to store (clear user, faculty, token...)
    commit('clearAuthData');

    // Redirect
    //router.replace('/login/');
    // Better to call refresh so all variables are set on default
    location.reload(); 
};


// Get version from db
export const prepareVersion = ({commit}, payload) => {
    axios.get('?module=other&action=get_version')
			.then(
				res => {
					if(!res.status == 200){				
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res})
					}
					else{
						commit('prepareVersion', res.data);
					}
				}
			)
			.catch(error => console.log(error))	
};


// Set active faculty and section for admin
export const changeFacultySection = ({commit}, payload) => {

    if(payload.data.faculty_id)
        commit('changeFaculty', payload.data.faculty_id);

    // Get data for new faculty (statistics)
    sections_list.default.actions.prepareSectionsStat({commit});

    if(router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela'){
        router.replace('/program-dela/podrobnosti/'+payload.data.section_id).catch(err=>{});
    }
    else
        router.replace('/podrocja/podrobnosti/'+payload.data.section_id).catch(err=>{});
    
};

// Get all faculties from database
export const prepareFaculties = ({commit}, payload) => {
    axios.get('?module=faculty&action=get_faculties')
			.then(
				res => {
					if(!res.status == 200){				
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res})
					}
					else{
						commit('prepareFaculties', res.data);
					}
				}
			)
			.catch(error => console.log(error))	
};

// Set active faculty for admin
export const changeFaculty = ({commit, dispatch}, faculty_id) => {

    commit('changeFaculty', faculty_id);

    // Get data for new faculty (statistics)
    sections_list.default.actions.prepareSectionsStat({commit});

    // Get data for attachments for this faculty
    sections_attachments.default.actions.getFacultyAttachments({commit});

    // Get data for departments of this faculty
    evalvation.default.actions.getDepartmentsListForFaculty({commit});

    // If we are in evalvation, redirect to evalvation
    if(router.currentRoute.matched[0].path == '/samoevalvacija/' || router.currentRoute.matched[0].path == '/samoevalvacija'){

        //To refresh internal questionnaire if faculty has it
        if(evalvation_questionnaire.default.getters.faculties_internal_permission().includes(faculty_id) && 
            (router.currentRoute.name == 'questionnaireBuild' || router.currentRoute.name == 'questionnaireBuildDoc')){
            evalvation_questionnaire.default.actions.getQuestionnaireDetails({commit, dispatch}, 
                    {type: router.currentRoute.name == 'questionnaireBuildDoc' ? 4 : 1});
        }
        
        if(router.currentRoute.name == 'evalvationPrograms'){
            evalvation.default.actions.getProgramsListForFaculty({commit});
        }
        else if(router.currentRoute.name == 'departmentsQuestionnaire'){
            evalvation_questionnaire.default.actions.getQuestionnaireDetails({commit}, {type: '2_'+faculty_id});
        }
        //router.replace('/samoevalvacija/').catch(err=>{});
    }

    else if(router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela'){
        router.replace('/program-dela/').catch(err=>{});
    }
        
    // Redirect to sections list
    else
        router.replace('/podrocja/').catch(err=>{});
};

// Change school year - set year (const year) and then get all sections from db
export const changeYear = ({commit, dispatch}, year) => {
    //general_mutations.showModalWorking(general_state.state);
    commit('changeYear', year);

    dispatch('isActiveYear', {commit, dispatch});

    if(general_state.state.user.access_evalvation == 1){
        evalvation.default.actions.resetProgramsMultipleFill({commit});

        if(general_state.state.user.type > 0){
            evalvation.default.actions.getDepartmentsListForFaculty({commit});
            evalvation.default.actions.getProgramsListForFaculty({commit});
        }
        else{
            evalvation.default.actions.getDepartmentsListForUser({commit});
            evalvation.default.actions.getProgramsListForUser({commit});
        }

        if(general_state.state.user.type > 1){
            evalvation.default.actions.getProgramsListAll({commit});
            analytics.default.actions.getEvalvationStatisticsByFaculty({commit});            
        }
    }
    
    if(router.currentRoute.name == 'questionnaireBuild'){
        evalvation_questionnaire.default.actions.getQuestionnaireDetails({commit, dispatch}, {type: 1, year: year});
    }
    else if(router.currentRoute.name == 'questionnaireBuildDoc'){
        evalvation_questionnaire.default.actions.getQuestionnaireDetails({commit, dispatch}, {type: 4, year: year});
    }
    else if(router.currentRoute.name == 'sectionsDashboard'){
        sections_list.default.actions.getDashboard({commit});
    }
    else if(general_state.state.user.access_sections == 1 || general_state.state.user.access_work_plan == 1){
        // Get data for this year and faculty
        sections_list.default.actions.prepareSections({commit});
        sections_list.default.actions.getSectionsIntro({commit});
        sections_list.default.actions.prepareSectionsStat({commit});
        sections_attachments.default.actions.getFacultyAttachments({commit});
        sections_list.default.actions.getSectionsImages({commit});

        // Redirect to sections list
        if(router.currentRoute.matched[0].path == '/podrocja/' || router.currentRoute.matched[0].path == '/podrocja')
            router.replace('/podrocja/').catch(err=>{});
        if(router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela')
            router.replace('/program-dela/').catch(err=>{});
    }
    //general_mutations.hideModalWorking(general_state.state);

    //just in case
    else if(router.currentRoute.matched[0].path == '/samoevalvacija/' || router.currentRoute.matched[0].path == '/samoevalvacija')
        router.replace('/samoevalvacija/').catch(err=>{});
};

// Is chosen yer an active year - if not, show warning(s)
export const isActiveYear = ({commit, dispatch}) => {
    if(general_state.state.year == general_state.state.active_year_date.active_year){
            return true;
    }
    else{
        general_mutations.showModal(general_state.state, {component: 'ModalWarning', data: {title: 'Opozorilo', 
            description: 'Izbrali ste študijsko leto, ki trenutno ni aktivno. Pri urejanju podatkov bodite še posebej previdni!'}});
        return false;
    }
};

// Is chosen year an active year - if not, show warning(s)
export const getActiveYear = ({commit, dispatch}) => {
    let current_date = new Date();
    let active_year_date = general_state.state.active_year_date;
    let year = current_date.getFullYear();

    if(current_date.getMonth() > active_year_date.month ||
        (current_date.getMonth() == active_year_date.month && current_date.getDate() >= active_year_date.day)){
            general_state.state.year = (year-1)+'/'+year;
            general_state.state.active_year_date.active_year = (year-1)+'/'+year;
    }
    else{
        general_state.state.year = (year-2)+'/'+(year-1);
        general_state.state.active_year_date.active_year = (year-2)+'/'+(year-1);
    }
};

// Set active faculty for admin
export const downloadFile = (_, {url, filename=''}) => {
    //some browsers blocks download if we open link in another tab
    //window.open(res.data.export_link); 

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};