import axios from 'axios'
import * as general_mutations from '../mutations';
import * as general_state from '../store';
import * as general_actions from '../actions';
import * as sections_attachments from '../modules/sections_attachments';
import router from '../../router/router'

const state = {

	// All sections in year for faculty
	sections: {},

	// General sections intro
	sections_intro: '',

	// Data for dashboard list
	dashboard_list_data: {},

	// Percentage of completed fields for progress bar
	sections_statistics: {},

	// List of images foe sections
	sections_images: {},

	// All work plan sections in year for faculty
	/* 	work_plan_sections: {},
	
		// General work plan sections intro
		work_plan_sections_intro: '',
		
		// Data for work plan dashboard list
		work_plan_dashboard_list_data: {},
	
		// Percentage of completed fields in work plan sections for progress bar
		work_plan_sections_statistics: {}, */
};


const getters = {

	// Return all sections
	sections: store => {
		return state.sections;
	},

	// Return general sections intro
	sections_intro: store => {
		return state.sections_intro;
	},

	// Return list for dashboard
	dashboard_list_data: store => {
		return state.dashboard_list_data;
	},

	// Return percentage of complited fields for progress bar
	sections_statistics: state => {
		return state.sections_statistics;
	},

	// Return list of images for sections
	sections_images: state => {
		return state.sections_images;
	},

	// Return percentage of complited fields in work plan sections for progress bar
	/* work_plan_sections_statistics: state => {
		return state.work_plan_sections_statistics;
	},

	// Return all work plan sections
	work_plan_sections: store => {
		return state.sections;
	},

	// Return general work plan sections intro
	work_plan_sections_intro: store => {
		return state.sections_intro;
	},

	// Return work plan list for dashboard
	work_plan_dashboard_list_data: store => {
		return state.dashboard_list_data;
	}, */
};


const mutations = {

	// Update progress bar data - statistics
	prepareSectionsStat: (state, payload) => {
		state.sections_statistics = payload.data;
	},

	// Update work plan progress bar data - statistics
	/* prepareSectionsStatWorkPlan: (state, payload) => {
		state.work_plan_sections_statistics = payload.data;
	}, */

	// Prepare list of all sections for user
	addSection: (state, payload) => {
		state.sections = payload;
	},

	// Prepare list of all work plan sections for user
	/* addSectionWorkPlan: (state, payload) => {
		state.work_plan_sections = payload;
	}, */

	// Edit subsection in array
	editSection: (state, payload) => {
		let section_id = payload.get('section_id');
		state.sections[section_id].name = payload.get('name');
		state.sections[section_id].description = payload.get('description');
		state.sections[section_id].image_id = payload.get('image');
		state.sections[section_id].deadline = payload.get('deadline');
		state.sections[section_id].deadlineClosed = payload.get('deadlineClosed');
		state.sections[section_id].faculty = JSON.parse(payload.get('visibility'));
	},

	// Remove subsection from array
	deleteSection: (state, section_id) => {

		delete state.sections[section_id];

		// Force refresh - VUE SET...?
		var temp = state.sections;
		state.sections = {};
		state.sections = temp;
	},

	// Prepare list of all sections for user
	prepareSections: (state, payload) => {
		state.sections = payload.data;
	},

	// Prepare list of all work plan sections for user
	/* prepareSectionsWorkPlan: (state, payload) => {
		state.work_plan_sections = payload.data;
	}, */

	// Update general sections intro
	updateSectionsIntro: (state, payload) => {
		state.sections_intro = payload;
	},

	// Update general work plan sections intro
	/* updateSectionsIntroWorkPlan: (state, payload) => {
		state.work_plan_sections_intro = payload;
	}, */

	// Update sections images list
	getSectionsImages: (state, payload) => {
		state.sections_images = payload;
	},

	allSectionsSubmitted: (state) => {
		for (let section_id in state.sections_statistics) {
			state.sections_statistics[section_id].submitted = 1;
		}
	},

	allSectionsSubmittedRepeal: (state) => {
		for (let section_id in state.sections_statistics) {
			state.sections_statistics[section_id].submitted = 0;
		}
	},

	setDashboard: (state, payload) => {
		state.dashboard_list_data = payload;
	},

	/* setDashboardWorkPlan: (state, payload) => {
		state.work_plan_dashboard_list_data = payload;
	}, */

	dashboardAddLastStatusByFaculty: (state, payload) => {
		state.dashboard_list_data.by_faculty[payload.id].faculty_data['last_status'] = payload.status;
	},

	dashboardAddLastStatusBySection: (state, payload) => {
		state.dashboard_list_data.by_section.section_data[payload.id]['last_status'] = payload.status;
	},

};


const actions = {

	// Prepare list of sections for user (based on faculty and year)
	prepareSections: ({ commit }) => {
		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.get('?module=sections&action=get_list&user_type=' + general_state.state.user.type + '&faculty=' + general_state.state.faculty.id + '&year=' + general_state.state.year + '&tab=' + tab)
			.then(
				res => {
					commit('prepareSections', res);
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Add new section - post api call to add to db - if no error, add it to array (const section) 
	addSection: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		
		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.post('?module=sections&action=add_section&year=' + general_state.state.year + '&tab=' + tab, payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : res.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('addSection', res.data.sections);
						dispatch('prepareSectionsStat');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Edit existing section 
	editSection: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=sections&action=edit_section&section_id=' + payload.get('section_id'), payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : res.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('editSection', payload);
						dispatch('prepareSectionsStat');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Delete existing section 
	deleteSection: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=sections&action=delete_section&section_id=' + payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('deleteSection', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Get general intro for section list
	getSectionsIntro: ({ commit }) => {
		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.get('?module=sections&action=get_intro&tab=' + tab +'&year=' + general_state.state.year)
			.then(
				res => {
					if (!res.status == 200) {
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: res })
					}
					else {
						commit('updateSectionsIntro', res.data);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Get list of images for sections
	getSectionsImages: ({ commit }) => {
		axios.get('?module=sections&action=get_sections_images')
			.then(
				res => {
					if (!res.status == 200) {
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: res })
					}
					else {
						commit('getSectionsImages', res.data);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Update general intro for section list 
	updateSectionsIntro: ({ commit }, payload) => {
		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.post('?module=sections&action=save_intro&tab=' + tab +'&year=' + general_state.state.year, payload)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('updateSectionsIntro', payload.value);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Get percentage of completed sections (section statistics)
	prepareSectionsStat: ({ commit }) => {
		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.post('?module=sections&action=get_stat&faculty=' + general_state.state.faculty.id + '&year=' + general_state.state.year + '&tab=' + tab)
			.then(
				res => {
					commit('prepareSectionsStat', res);
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Send a submittion of all sections
	sendSectionsSubmittion: ({ commit }) => {
		general_mutations.showModalWorking(general_state.state);

		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.post('?module=sections&action=submit_sections&faculty=' + general_state.state.faculty.id + '&year=' + general_state.state.year + '&tab=' + tab)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : "Napaka";
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('allSectionsSubmitted');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Send a repeal of submittion of all sections
	sendSectionsSubmittionRepeal: ({ commit }) => {
		general_mutations.showModalWorking(general_state.state);

		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.post('?module=sections&action=repeal_submit_sections&faculty=' + general_state.state.faculty.id + '&year=' + general_state.state.year + '&tab=' + tab)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : "Napaka";
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('allSectionsSubmittedRepeal');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Get dashboard for all faculties
	getDashboard: ({ commit }) => {
		general_mutations.showModalWorking(general_state.state);

		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.post('?module=sections&action=get_dashboard&year=' + general_state.state.year + '&tab=' + tab)
			.then(
				res => {
					if (!res.data) {
						var error = res.data.error ? res.data.error : "Napaka";
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('setDashboard', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},


	// Upload file for all sections
	uploadSectionsFile: ({ commit }, payload) => {
		let formData = new FormData();
		formData.append('file', payload);

		axios.post('?module=upload&action=upload_sections_file', formData)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : formData;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						//commit('uploadSectionsFile', payload.value);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Add last status stamp for filter in dashboard by faculty
	dashboardAddLastStatusByFaculty: ({ commit }, payload) => {
		commit('dashboardAddLastStatusByFaculty', payload);
	},

	// Add last status stamp for filter in dashboard by section
	dashboardAddLastStatusBySection: ({ commit }, payload) => {
		commit('dashboardAddLastStatusBySection', payload);
	},

	// Export section data
	exportSectionDataAll: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.get('?module=export&action=get_section_export_all&format='+payload.format+'&faculty='+general_state.state.faculty.id+'&year='+general_state.state.year+'&tab='+tab)
			.then(
				res => {
					if(!res.data.success){		
						general_mutations.hideModalWorking(general_state.state);		
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						general_mutations.hideModalWorking(general_state.state);

						dispatch('downloadFile', {url:res.data.export_link, filename:res.data.export_filename});

						// show infor for content table for word
						if(payload.format == 'doc'){
							general_mutations.showModal(general_state.state, { component: 'ModalWarning', 
								data: { title: "Posodobitev kazala vsebine", description: '<p>Ko prvič odprete dokument v Microsoft Wordu, posodobite kazalo vsebine z naslednjimi koraki:</p><ol><li>Desni klik na kazalo vsebine.</li><li>Izberite <strong>"Posodobi polje"</strong>.</li><li>Izberite <strong>"Posodobi celotno kazalo"</strong>.</li></ol><p>To bo zagotovilo, da bodo številke strani v kazalu pravilno prikazane.</p>' } })
						}


					}
				}
			)
			.catch(error => {qa
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	//Export package all sections data
	exportSectionDataAllPackage: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.get('?module=export&action=get_section_export_all_package&format='+payload.format+'&faculty='+general_state.state.faculty.id+'&year='+general_state.state.year+'&tab='+tab)
			.then(
				res => {
					if(!res.data.success){		
						general_mutations.hideModalWorking(general_state.state);		
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						general_mutations.hideModalWorking(general_state.state);
						dispatch('downloadFile', {url:res.data.export_link, filename:res.data.export_filename});
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Export section data
	exportSectionDataFacultiesPackage: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;
		axios.get('?module=export&action=get_section_export_faculties_package&format='+payload.format+'&year='+general_state.state.year+'&tab='+tab)
			.then(
				res => {
					if(!res.data.success){				
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						general_mutations.hideModalWorking(general_state.state);
						dispatch('downloadFile', {url:res.data.export_link, filename:res.data.export_filename});
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	//Copy all sections from previous to this year
	CopySectionsPreviousYear: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let tab = router.currentRoute.matched[0].path == '/podrocja' ? 0 : 1;

		let years_keys = Object.keys(general_state.state.years);
		let year_index = years_keys.indexOf(general_state.state.year);
		let year_previous = years_keys[year_index+1];

		if(year_previous)
			axios.get('?module=sections&action=copy_sections&year_from='+year_previous+'&year_to='+general_state.state.year+'&tab='+tab)
				.then(
					res => {
						if(res.data.error){				
							general_mutations.hideModalWorking(general_state.state);
							general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
						}
						else{
							general_mutations.hideModalWorking(general_state.state);
							dispatch('prepareSections');
							dispatch('prepareSectionsStat');
						}
					}
				)
				.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	//Set sections hidden
	toggleHideSections: ({commit, dispatch}, payload) => {
		let hide = (payload.hide == 1) ? 0 : 1;
		axios.get('?module=sections&action=toggle_hide_sections&hide='+hide+'&year='+general_state.state.year+'&tab='+payload.tab)
			.then(
				res => {
					if(!res.data.success){		
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						dispatch('prepareSections'); 
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			 
	},
};


export default {
	state,
	getters,
	actions,
	mutations
}